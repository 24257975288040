import React from 'react';
import styled from 'styled-components';
import Axios from 'axios' ;
import { backEndAdress } from '../../../../variables/back-endAdress';
import SendIcon from '@mui/icons-material/Send';
import { IndividualSessionContext } from '../../IndividualSession';
import UICard from '../../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

const MailJobs = ({ individualSession }) => {

    const [mailJobs, setMailJobs] = React.useState([]);
    const { setJobPreview } = React.useContext(IndividualSessionContext);

    React.useEffect(() => {
        const fetchMailJobs = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-jobs/for-instance/${individualSession._id}`)
                setMailJobs(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchMailJobs();
    }, []);

    const rendering = () => {
        return (
            <UICard styleTags={{ marginLeft : "20px", marginTop : "20px"}}>
                <MailJobsSC>
                    <div className='title-component' style={{ paddingTop : 0}}>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Scheduled e-mails</h1>
                    </div>
                    {mailJobs.length === 0 && 
                        <div className='no-mails-div'>
                            <p>there are currently no mails scheduled</p>
                        </div>
                    }
                    {mailJobs.length > 0 && mailJobs.map((mailJob) => {
                        return (
                            <div className='mail-job' onClick={() => setJobPreview(mailJob)}>
                                <div style={{display : 'flex', alignItems : 'center', flex : 1}}>
                                    <SendIcon />
                                    
                                    <div className='mail-job-info'>
                                        <h1 className='mail-job-title'>{mailJob.subject}</h1>
                                        <h3 className='sendto'>{mailJob.sendTo}sd kflmsd fjlmds jfdsmk j</h3>
                                    </div>
                                </div>
                                <div className='status'>
                                    <p>sent</p>
                                </div>

                            </div>
                        )
                    })}
                </MailJobsSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailJobsSC = styled.div`
    background-color : white;
    margin-top : 20px;
    min-height : 400px;
    max-width : 100%;
    overflow : hidden;
    display : flex; 
    flex-direction : column;
    
    .mail-job {
        align-items : center;
        border-bottom : 1px solid lightgrey;
        justify-content : space-between;
        display : flex;
        padding : 10px;

        &:hover {
            background-color : #F5F5F5;
            cursor : pointer;
        }

        svg {
            font-size : 18px;
            color : darkgrey;
            min-width : 50px;
            max-width : 50px;            
        }

        .mail-job-info {
            flex : 1;
            overflow : hidden;
            max-width : 100%;
            display : flex;
            flex-direction : column;
            max-width : 180px;

            h1, h3, p {
                display: -webkit-box;        /* Use a flexible box layout */
                -webkit-box-orient: vertical; /* Make the box behave vertically */
                -webkit-line-clamp: 1;        /* Limit to 2 lines */
                overflow: hidden;            /* Hide overflowed text */
                text-overflow: ellipsis;     /* Add ellipsis at the end */
            }
        }

        .status {
            min-width : 60px;
            max-width : 60px;
            display : flex;
            justify-content : center;

            p {
                margin-right : 20px;
                font-size : 14px;
                color : #A97C19;
                background-color : #FEFFA7;
                font-weight : 200;
                padding : 5px;
                padding-left : 15px;
                padding-right : 15px;
                border-radius : 5px;
            }
        }
    }

    .no-mails-div {
        display : flex;
        flex-direction : column;
        align-items : center;
        justify-content : center;
        margin-top : 50px;

        img {
            width : 150px;
            height : 150px;
        }

        p {
            font-size : 14px;
            color : #595959;
            margin-top : 10px;
        }
    }

`

export default MailJobs