import React from 'react';
import styled from 'styled-components';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import TypeSelector from './TypeSelector';
import TriggerScheduledSelector from './TriggerScheduledSelector';
import { IndividualTrainingContext } from '../IndividiualTraining';
import Mails from './Mails';

const MailSchedule = () => {

    const [type, setType] = React.useState('our-location');
    const [triggerScheduled, setTriggerScheduled] = React.useState('Trigger based e-mails');
    const { individualTraining } = React.useContext(IndividualTrainingContext);
    const [mailSchedule, setMailSchedule] = React.useState([]);

    React.useEffect(() => {

        const getMailSchedule = () => {

            let allMails = []; 

            if(type === 'our-location') {
                allMails = individualTraining.defaultTouchPointsArray;
            } else {
                allMails = individualTraining.customerLocationTouchPointsArray; 
            }

            const filteredMails = allMails.filter(mail => mail.timingMode === (triggerScheduled === 'Trigger based e-mails' ? 'Trigger' : 'Scheduled'));
            setMailSchedule(filteredMails);
        }

        getMailSchedule();

    }, [type, triggerScheduled]);

    const rendering = () => {
        return (
            <UICard styleTags={{ marginLeft : '20px', marginRight : '20px', marginLeft : '0px', flex : 1}}>
                <MailScheduleSC>
                    <div className='title-component'>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Mail schedule</h1>
                    </div>
                    <TypeSelector type={type} setType={setType}/>

                    <div className='canvas'>
                        <TriggerScheduledSelector
                            triggerScheduled={triggerScheduled}
                            setTriggerScheduled={setTriggerScheduled}
                        />
                        <Mails mailSchedule={mailSchedule}/>
                    </div>
                </MailScheduleSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailScheduleSC = styled.div`
    display : flex; 
    flex-direction : column;
    max-height : 400px;
    min-height : 400px;

    .canvas {
        display : flex; 
        flex-direction : column;
        background-color : #fafafa;
        margin : 20px;
        flex : 1;
        
    }
`

export default MailSchedule