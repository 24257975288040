import React from 'react';
import styled from 'styled-components';
import MonthSelection from './MonthSelection';
import Calendar from './Calendar';
import TrainingsPerTrainer from './TrainingsPerTrainer';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

export const TrainingCalendarContext = React.createContext();

const TrainingCalendar = () => {

    const [currentMonth, setCurrentMonth] = React.useState('January');

    React.useLayoutEffect(() => {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const currentMonthName = monthNames[new Date().getMonth()];
        setCurrentMonth(currentMonthName);
    }
    , []);

    const getDaysInMonth = (month, year) => {
        return new Date(year, new Date(`${month} 1, ${year}`).getMonth() + 1, 0).getDate();
    };

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const rendering = () => {
        const monthIndex = months.indexOf(currentMonth) + 1;
        const dates = [];

        for (let day = 1; day <= getDaysInMonth(currentMonth, 2024); day++) {
            dates.push(new Date(2024, monthIndex - 1, day));
        }

        return (
            <TrainingCalendarContext.Provider value={{ 
                months, 
                currentMonth, 
                setCurrentMonth 
            }}>
                <UICard styleTags={{ margin : "20px", marginRight :0}}>
                    <TrainingCalendarSC>
                        <div className='title-component' style={{ padding : 0, paddingBottom : '20px'}}>
                            <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                            <h1 className='card-title'>Training calendar</h1>
                        </div>
                        <MonthSelection  />
                        <Calendar dates={dates} />
                        <TrainingsPerTrainer />
                    </TrainingCalendarSC>
                </UICard>
            </TrainingCalendarContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingCalendarSC = styled.div`
    padding : 20px;
    overflow-x : scroll;
    min-height : 290px;
`

export default TrainingCalendar