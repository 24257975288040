import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import EmailEditor from 'react-email-editor';
import SideBarMailEditor from './SidebarMailEditor';
import SaveTemplateModal from './SaveTemplateModal';
import { AppContext } from '../../App';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import PopupSelectMailTemplate from './PopupSelectMailTemplate';
import VariablesPopUp from './VariablesPopUp';
import TestMailPopUp from './testMailPopUp/TestMailPopUp';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../GeneralComponents/Modal';
import { Spinner } from 'react-bootstrap';
import LoadingSpinner from '../GeneralComponents/LoadingSpinner';
import { Button, CircularProgress } from '@mui/material';

const MailEditor = () => {

    const { appState , setAppState } = React.useContext(AppContext);

    const [state, setState] = useState({
        currentDesignName : null, 
        saveDesignPopup : false,
        selectDesignPopUp : false,
        templates : [],
        designToLoad : null,
        fullyLoaded : false,
        variablesPopUp : false,
        testMailPopUp : false,
        searchQuery : '',
        fullScreen : false,
        saveDesignLoading : false,
    });

    const [allTrainings, setAllTrainings] = useState([]);
    const [allInstances, setAllInstances] = useState([]);
    const [currentTraining, setCurrentTraining] = useState(null);
    const [currentInstance, setCurrentInstance] = useState(null);
    const [selectedFolder, setSelectedFolder] = React.useState('general');

    const titleRef = useRef(null);
    const emailEditorRef = useRef(null);
    const trainingRef = React.useRef(null);
    const instanceRef = React.useRef(null);
    const testMailInputRef = React.useRef(null);
    const subjectLineRef = React.useRef(null);
    const titleTemplateRef = React.useRef(null);

    //Get all trainings
    React.useEffect(() => {
        const getTrainings = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings/`);
                setAllTrainings(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getTrainings();
    }, []);

    //Get instances of selectedTraining
    React.useEffect(() => {
        if(currentTraining) {
            const getInstances = async () => {
                try {
                    const response = await Axios.get(`${backEndAdress}/api/instances/training/${currentTraining._id}`);
                    setAllInstances(response.data);
                } catch (error) {
                    console.error(error);
                }
            }

            getInstances();
        }
    }, [currentTraining]);

    //Get the design
    React.useEffect(() => {
        if(titleRef.current) {
            setState({...state, currentDesignName : titleRef.current.value})
        }
    },[titleRef.current])
    
    //Load the design
    React.useEffect(() => {
        if(state.designToLoad) {
            emailEditorRef.current.editor.loadDesign(state.designToLoad.design);
            setState({
                ...state, 
                currentDesignName : state.designToLoad.title,
                selectDesignPopUp : false,
            })
            if(state.designToLoad.folder) {
                setSelectedFolder(state.designToLoad.folder);
            }
        }
    },[state.designToLoad])

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        });
    };
    
    const onReady = () => {
        const unlayer = emailEditorRef.current.editor;
        unlayer.setMergeTags({
            user : {
                name : 'User', 
                mergeTags : {
                    first_name: {
                        name: "First Name",
                        value: "{{first_name}}",
                        sample: "John"
                      },
                      last_name: {
                        name: "Last Name",
                        value: "{{last_name}}",
                        sample: "Doe"
                      },
                      imageURL : {
                          name : 'Image URL',
                          value : '{{imageURL}}',
                          sample : 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
                      }
                }
            },
            trainer : {
                name : 'Trainer', 
                mergeTags : {
                    trainer_name : {
                        name : 'Trainer Name',
                        value : '{{trainer_name}}',
                        sample : 'Gille'
                    },
                    trainer_email : {
                        name : 'Trainer Email',
                        value : '{{trainer_email}}',
                        sample : 'gille.buyle@credon.eu'
                    },
                    trainer_phone : {
                        name : 'Trainer Phone',
                        value : '{{trainer_phone}}',
                        sample : '+32474000000'
                    },
                    trainer_description : {
                        name : 'Trainer Description',
                        value : '{{trainer_description}}',
                        sample : 'Gille is a very good trainer'
                    },
                    trainer_image : {
                        name : 'Trainer Image',
                        value : '{{imageUrlTrainer}}',
                        sample : 'https://cdn-icons-png.flaticon.com/512/6858/6858504.png'
                    }, 
                    trainer_linkedin : {
                        name : 'Trainer LinkedIn',
                        value : '{{trainer_linkedin}}',
                        sample : 'https://www.linkedin.com/in/gille-buyle-2b7b1b1b/'
                    }
                }
            },
            training : {
                name : 'Training',
                mergeTags : {
                    training_name : {
                        name : 'Training name', 
                        value : '{{training_name}}',
                        sample : 'PowerBI Advanced'
                    },
                    dates_concatted : {
                        name : 'Dates concatted',
                        value : '{{dates_concatted}}',
                        sample : '2021-06-01, 2021-06-02'
                    },
                    short_description : {
                        name : 'short description',
                        value : '{{short_description}}',
                        sample : 'During this training you will learn how to make a dashboard from scratch using the latest version of powerBI. We will also teach you how to collaborate on different dashboards in cloud.'
                    },
                    requirements : {
                        name : 'requirements',
                        value : '{{requirements}}',
                        sample : 'It is important that you bring a laptop which has the latest version of PowerBI installed. You also need to have a good understanding of the basics of PowerBI.'
                    }
                }
            },
            location : {
                name : 'Location',
                mergeTags : {
                    adress : {
                        name : 'Adress', 
                        value : '{{adress}}',
                        sample : 'Industrieweg 115 9032 Gent - Wondelgem'
                    },
                    instructions : {
                        name : 'Instructions',
                        value : '{{instructions}}',
                        sample : 'When exiting the e313 follow the bridge. Dont use your gps, it will lead you to the wrong place. Call us if you have any questions.'
                    },
                    instructions_image_url : {
                        name : 'Instructions Image URL',
                        value : '{{instructions_image_url}}',
                        sample : 'https://miro.medium.com/v2/resize:fit:1400/1*qYUvh-EtES8dtgKiBRiLsA.png'
                    }   
                }
            },
            dates: {
                name: "dates",
                rules: {
                  repeat: {
                    name: "Repeat for Each Date",
                    before: "{{#dates}}",
                    after: "{{/dates}}",
                  }
                },
                mergeTags: {
                  date: {
                    name: "date",
                    value: "{{date}}"
                  }
                }
            },
            survey : {
                name : 'Survey',
                mergeTags : {
                    survey_link : {
                        name : 'Survey Link',
                        value : '{{survey_link}}',
                        sample : 'https://www.google.com'
                    }
                }
            },
            certificate : {
                name : 'Certificate',
                mergeTags : {
                    survey_link : {
                        name : 'Certificate link',
                        value : '{{certificate_url}}',
                        sample : 'https://www.google.com'
                    }
                }
            }
        });
        setState({...state, fullyLoaded : true})
    };

    const saveDesignFunction = () => {

        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;

            const templateInfo = {
                title : document.getElementById('title-template-save-bar') ? document.getElementById('title-template-save-bar').value : document.getElementById('title-template').value,
                subjectLine : document.getElementById('subject-line').value,
                design,
                tenant : appState.tenant,
                folder : selectedFolder ? selectedFolder : 'general', 
                mailTemplateId : state.designToLoad ? state.designToLoad._id : null,
            }

            const formData = new FormData();
            formData.append('mailTemplateInfo', JSON.stringify(templateInfo));
            formData.append('mailTemplate', new Blob([html], {type : 'text/html'}));

            const saveTemplate = async () => {
                try {
                    const response = await Axios.post(`${backEndAdress}/api/mail-templates`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                    if(!state.designToLoad) {


                        const templateInfoWithIdFromMongoAsMailTemplateId = {
                            ...templateInfo,
                            mailTemplateId : response.data._id
                        }

                        console.log('granting a mailtemplate id to the new mail template', templateInfoWithIdFromMongoAsMailTemplateId); 

                        const formData2 = new FormData();
                        formData2.append('mailTemplateInfo', JSON.stringify(templateInfoWithIdFromMongoAsMailTemplateId));
                        formData2.append('mailTemplate', new Blob([html], {type : 'text/html'}));

                        const updateMailId = await Axios.post(`${backEndAdress}/api/mail-templates`, formData2 , { headers : { 'Content-Type' : 'multipart/form-data' } });

                        console.log('updateMailId', updateMailId);

                    }
                    setState({
                        ...state,
                        saveDesignPopup : false,
                        duplication : false,
                        saveDesignLoading : false,
                        designToLoad : response.data
                    });
                } catch (error) {
                    console.log('error', error);
                }
            }

            saveTemplate()   
        });
    }

    const sendTestMailFunction2 = (e) => {

        e.preventDefault();
        const email = testMailInputRef.current.value;
        const unlayer = emailEditorRef.current.editor;

        unlayer.exportHtml((data) => {  
            const { html } = data;

            const sendToInfo = {
                email
            }

            const formData = new FormData();
            formData.append('sendToInfo', JSON.stringify(sendToInfo));
            formData.append('mailTemplate', new Blob([html], {type : 'text/html'}));

            const sendTestMail = async () => {
                try {
                    const response = await Axios.post(`${backEndAdress}/api/mail-templates/send-test-mail`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                } catch (error) {
                    console.log('error', error);
                }
            }

            sendTestMail()

        },{
            mergeTags : {
                first_name : 'John',
                last_name : 'Doe',
                training_name : 'PowerBI Advanced',
                imageURL : 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
                dates : {
                    repeat : [
                        { date : '2021-06-01'},
                        { date : '2021-06-02'},
                    ]
                }
            }
        });    
    };
     
    const rendering = () => {
        return (
            <>
            <MailEditorSC>
                <>
                    {!state.fullyLoaded && 
                        <div 
                            className='loading-spinner-modal' 
                            style={{ 
                                minWidth : '250px', 
                                backgroundColor : 'white',
                                display : 'flex',
                                flexDirection : 'column',
                                alignItems : 'center',
                                padding : '30px',
                                borderRadius : '10px',
                                position : 'absolute',
                                top : '250px', 
                                left : 0,
                                right : 0,
                                bottom : 0
                            }}>
                            <CircularProgress />
                            <p style={{paddingTop : '15px'}}>loading the mail editor</p>
                        </div>
                    }
                    {state.saveDesignPopup && 
                        <SaveTemplateModal 
                            saveDesignFunction={saveDesignFunction} 
                            titleRef={titleRef}
                            state={state}
                            setState={setState}
                            selectedFolder={selectedFolder}
                            setSelectedFolder={setSelectedFolder}
                            titleTemplateRef={titleTemplateRef}
                        />
                    }

                    {state.selectDesignPopUp &&
                        <PopupSelectMailTemplate 
                            setState={setState}
                            state={state}
                        />
                    }

                    {state.variablesPopUp &&
                        <VariablesPopUp 
                            setState={setState}
                            state={state}
                        />
                    }

                    {state.testMailPopUp &&
                        <TestMailPopUp 
                            setState={setState}
                            state={state}
                            allTrainings={allTrainings}
                            allInstances={allInstances}
                            currentTraining={currentTraining}
                            currentInstance={currentInstance}
                            setCurrentTraining={setCurrentTraining}
                            setCurrentInstance={setCurrentInstance}
                            trainingRef={trainingRef}
                            testMailInputRef={testMailInputRef}
                            sendTestMailFunction={sendTestMailFunction2}
                        />
                    }
                </>

                <SideBarMailEditor 
                    exportHtml={exportHtml} 
                    saveDesignFunction={saveDesignFunction}
                    state={state}
                    setState={setState}
                    subjectLineRef={subjectLineRef}
                    titleTemplateRef={titleTemplateRef}
                />

                <div className={`wrapper-for-full-screen ${state.fullScreen && 'fullscreen'}`}>
                    {
                        state.fullScreen && 
                        <Button
                            variant='contained'
                            color='primary' 
                            className='exit-fullscreen' 
                            onClick={() => setState({
                                ...state, 
                                fullScreen : false
                            })}
                        > 
                                exit
                        </Button>}
                    
                    <EmailEditor
                        ref={emailEditorRef}
                        onReady={onReady}
                        saveDesign={saveDesignFunction}
                    />    
                </div>
            </MailEditorSC>
            </>
          );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailEditorSC = styled.div`
    min-height : 100%;
    max-height : 100%;
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    max-width : 100%;
    min-width : 1100px;
    overflow : hidden;
    margin-left : 15px;
    position : relative; 

    .wrapper-for-full-screen {
        flex-grow : 1;
        display : flex;
    }

    .fullscreen {
        position : absolute;
        top : 0;
        left : 0;
        right : 0;
        bottom : 0;
        display : flex;
        z-index : 1000;

        .exit-fullscreen {
            position : absolute;
            top : 10px;
            left : 10px;
            z-index : 1000;
        }
    }
`

export default MailEditor