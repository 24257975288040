import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';

const Options = () => {

    const rendering = () => {
        return (
            <OptionsSC>
                <h1>Settings</h1>
            </OptionsSC> 
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const OptionsSC = styled.div`
    align-items : center;
    display : flex;
    margin-right : 15px;

    p {
        padding-right : 30px;
        color : ${colors.lightGrey};
        font-weight : 500;
    }
`

export default Options