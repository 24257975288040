import React from 'react';
import styled from 'styled-components';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const Mails = ({ mailSchedule }) => {

    console.log('mail schedule ', mailSchedule);

    const rendering = () => {
        return (
            <MailsSC>
                {mailSchedule.map((mail, index) => {
                    return (
                        <div key={index} className='mail'>
                            <EmailOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                            <h1>{mail.name}</h1>
                            <h3>{mail.mailTemplate.title}</h3>
                        </div>
                    );
                })}
            </MailsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailsSC = styled.div`
    padding-left : 20px;
    display: flex;

    .mail {
        background-color : white; 
        padding : 20px;
        border-radius : 5px;
        min-width : 120px;
        min-height : 120px;
        max-width : 120px;

        display : flex;
        flex-direction : column;
        margin-right : 15px;

        

        &:hover {
            cursor : pointer;
        }


        svg {
            margin-left : -2px;
            color : #737373;
            font-size : 50px;
            margin-bottom : 10px;
        }

        h3 {
        display: -webkit-box;        /* Use a flexible box layout */
        -webkit-box-orient: vertical; /* Make the box behave vertically */
        -webkit-line-clamp: 2;        /* Limit to 2 lines */
        overflow: hidden;            /* Hide overflowed text */
        text-overflow: ellipsis;     /* Add ellipsis at the end */
        }
        
    }
`

export default Mails