import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SubNavigation = ({ navigationItems }) => {

    const [currentItem, setCurrentItem] = React.useState(navigationItems[0]);

    const navigate = useNavigate();

    const clickItem = (item) => {
        navigate(item.path)
        setCurrentItem(item);
    }

    const rendering = () => {
        return (
            <SubNavigationSC>
                {navigationItems.map((item, index) => {
                    return (
                        <div 
                            className='navigation-item' 
                            key={item.name}
                            onClick={() => clickItem(item)}    
                        >
                            <h1 className={`${currentItem.name === item.name ? 'color-main-blue' : 'color-black-medium'}`}>{item.name}</h1>
                        </div>
                    );
                })}
                {/* <button>add training</button>
                <button>Schedule session</button> */}
                </SubNavigationSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SubNavigationSC = styled.div`
    display : flex;
    margin-left : 20px;
    padding-top : 20px;

    .navigation-item {
        padding-right : 15px;
        &:hover {
            cursor : pointer;
        }
    }
`

export default SubNavigation