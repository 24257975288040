import React from 'react';
import styled from 'styled-components';
import { Switch } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { MailScheduleBothLocationsContext } from '../MailScheduleBothLocations';

const TouchPointTile = ({ title, description, template, touchPoint, clickFunction }) => {


    const rendering = () => {
        return (
            <TouchPointTileSC
                onClick={() => clickFunction()}
            >
                <div className='title-header'>
                    <h3>{title}</h3>
                    <Switch checked={true}/>
                </div>
                <div className='description'>
                    <p>{description}</p>
                </div>
                <div className='template'>
                    <EmailOutlinedIcon />
                    <p>{template ? template.title : 'no mail'}</p>
                </div>
            </TouchPointTileSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TouchPointTileSC = styled.div`
    display : flex;
    flex-direction : column;
    border : 1px solid lightgrey;
    border-radius : 5px;
    max-width : 230px;
    min-width : 230px;
    background-color : white; 
    
    .title-header {
        display: flex;
        justify-content: space-between;
        border-bottom : 1px solid lightgrey;
        align-items : center;
        padding : 5px;
        padding-left : 10px;

        h3 {
            font-size : 1.2rem;
            font-weight : 400;
            font-family : inherit;
            margin : 0;
        }

    }

    .description {
        padding : 10px;
        
        p {
            font-size : 0.9rem;
            font-weight : 300;
            font-family : inherit;
            margin : 0;
        }
    }

    .template {
        display : flex;
        align-items : center;
        padding : 10px;
        padding-top : 0;

        p {
            font-size : 0.9rem;
            font-weight : 300;
            font-family : inherit;
            margin : 0;
        }

        svg {
            font-size : 2.4rem;
            margin-right : 10px;
            color : lightgrey;
        }
    }
    
`

export default TouchPointTile