import React from 'react';
import styled from 'styled-components';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { IndividualTrainingContext } from '../IndividiualTraining';

const TriggerScheduledSelector = ({ triggerScheduled, setTriggerScheduled }) => {


    const [menu, toggleMenu] = React.useState(false);

    const clickMenu = (option) => {
        setTriggerScheduled(option);
        toggleMenu(false);
    }



    const rendering = () => {
        return (
            <TriggerScheduledSelectorSC>
                <div className='current' >
                        <div className='current-item' onClick={() => toggleMenu(!menu)}>
                        <p>{triggerScheduled}</p>
                        <KeyboardArrowDownOutlinedIcon />
                    </div>
                    {menu && 
                        <div className='menu'>
                            <p onClick={() => clickMenu('Trigger based e-mails')}>Trigger based e-mails</p>
                            <p onClick={() => clickMenu('Scheduled e-mails')}>Scheduled e-mails</p>
                        </div>
                    }
                </div>
            </TriggerScheduledSelectorSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TriggerScheduledSelectorSC = styled.div`
    .current {
        padding : 20px;
        font-size : 16px;
        display : flex;
        position : relative; 

        .current-item {
            display : flex;
            align-items : center;
            cursor : pointer;
            p {
                margin : 0;
            }
        }

    }

    .menu {
        top : 20px;
        left : 20px;

        background-color : white;
        max-width : 200px;
        border : 1px solid #e0e0e0;
        position : absolute;
        p {
            padding : 10px;
            cursor : pointer;
            transition : background-color 0.3s;

            &:hover {
                background-color : #f5f5f5;
            }
        }
    }
`

export default TriggerScheduledSelector