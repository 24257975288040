import React from 'react';
import styled from 'styled-components';
import Modal from '../../../GeneralComponents/Modal';
import { Button, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const SignupLinkModal = ({ individualSession, setSignupLinkModal}) => {


    const getURL = () => {
        if (process.env.NODE_ENV === 'development') {
            return 'http://localhost:3000';
        } else {
            return 'https://holli-training.com';
        }
    }

    const backEndAdress = getURL();

    const rendering = () => {
        return (
            <Modal>
                <SignupLinkModalSC>
                    <div className='link-container'>
                        <p className='link'>Link</p>
                        <p className='link-value'>{`${backEndAdress}/#/sign-up/${individualSession.trainingId}/${individualSession._id}`}</p>
                    </div>
                    <div className='buttons'>
                        <IconButton variant='outlined' color='primary' style={{ marginRight : '10px'}}>
                            <ContentCopyIcon
                                variant='outlined'
                                color='primary'
                                onClick={() => navigator.clipboard.writeText(`${backEndAdress}/#/sign-up/${individualSession.trainingId}/${individualSession._id}`)}
                            />
                        </IconButton>
                        <Button variant='outlined' color='primary' onClick={() => setSignupLinkModal(false)}>Close</Button>
                    </div>
                </SignupLinkModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SignupLinkModalSC = styled.div`
    background-color : white;
    padding : 25px;
    border-radius : 5px;

    .link-container {
        display : flex;
        border : 1px solid grey;
        border-radius : 5px;
        font-weight : 300;

        .link {
            margin-right : 10px;
            background-color : #1976d2;
            color : white;
            padding : 15px; 
            padding-right : 25px;
            padding-left : 25px;
        }

        .link-value {
            font-weight : 300;
            padding : 15px; 
        }
    }

    .buttons {
        display : flex;
        margin-top : 15px;
        justify-content : flex-end; 
    }



`

export default SignupLinkModal