import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../variables/Colors';

const TypeSelector = ({ type, setType }) => {

    console.log('type : ', type);

    const rendering = () => {
        return (
            <TypeSelectorSC>
                <div className='selector'>
                    <p 
                        style={{ 
                            backgroundColor : type === 'our-location' ? `${colors.primaryBlue}` : '#fafafa',
                            color : type !== 'our-location' ? `${colors.midGrey}` : 'white'    
                        }}
                        onClick={() => setType('our-location')}    
                    > 

                        our location</p>
                    <p 
                        style={{ 
                            backgroundColor : type === 'customer-location' ? `${colors.primaryBlue}` : '#fafafa',
                            color : type !== 'customer-location' ? `${colors.midGrey}` : 'white'    
                        }}
                        onClick={() => setType('customer-location')}    
                    >
    
                        Customer location</p>
                </div>
            </TypeSelectorSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TypeSelectorSC = styled.div`
    margin-left : 20px;
    
    .selector {
        display : flex; 
        border-radius : 5px;
        overflow : hidden;

        p {
            padding : 8px;
            padding-left : 15px;
            padding-right : 15px;
            color : white; 

            &:hover {
                cursor : pointer;
            }
        }
    }
`

export default TypeSelector