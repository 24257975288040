import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress'; 
import UpcomingTrainingTile from './UpcomingTrainingTile';
import Search from '../../GeneralComponents/Search';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

const UpcomingTrainings = () => {

    const [instances, setInstances] = React.useState([]);
    const [query, setQuery] = React.useState('');

    React.useEffect(() => {
        const getInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/`)
                const filteredInstances = response.data.filter(instance => new Date(instance.startDate) > new Date());
                setInstances(filteredInstances);
            } catch (error) {
                console.error(error);
            }
        }

        getInstances();

    }, []);

    const filteredInstances = instances.filter(instance => instance.trainingName.toLowerCase().includes(query.toLowerCase()));

    const rendering = () => {

        return (
            <UICard styleTags={{marginTop : "20px"}}>
                <UpcomingTrainingsSC>
                        <div className='title-component' style={{ padding : 0, paddingBottom : '20px'}}>
                            <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                            <h1 className='card-title'>Upcoming trainings</h1>
                        </div>
                        <Search
                            placeholder='Search registrations...'
                            style={{ marginBottom : '20px' }}
                            query={query}
                            setQuery={setQuery}
                        />
                        <div className='trainings-list'>
                        {filteredInstances.map(instance => {
                            return (
                                <UpcomingTrainingTile instance={instance}/>
                            );
                        })}
                        </div>
                </UpcomingTrainingsSC>
            </UICard>

        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UpcomingTrainingsSC = styled.div`
    padding : 20px;
    min-width : 300px;
    
    .trainings-list {
        display : flex;
        flex-direction : column;
        overflow-y : scroll;

        &::-webkit-scrollbar {
            display: none;
        }

    }

`

export default UpcomingTrainings