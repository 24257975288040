import React from 'react';
import styled from 'styled-components';

const NoAttendees = () => {

    const rendering = () => {
        return (
            <NoAttendeesSC>
                <p>There are no attendees for the current training</p>
                <p>you can only send out a survey to people that singed up for this session</p>
            </NoAttendeesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const NoAttendeesSC = styled.div`
    display : flex; 
    flex-direction : column;
    align-items : center;
    justify-content : center;
    padding-top : 150px;
`

export default NoAttendees