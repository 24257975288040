import React from 'react';
import styled from 'styled-components';
import TrainingTiles from '../TrainingsOverview/TraingTiles';
import TrainingsOverview from '../TrainingsOverview/TrainingsOverview';
import { Route, HashRouter as Router, Routes  } from 'react-router-dom';
// import IndividualTraining from '../IndividualTraining/IndividualTraining';
// import IndividualTraining from '../IndividualTrainingReform/IndividualTraining';
import SignUpForm from '../SignUpForm/SignUpForm';
import AddTraining from '../addTraining/AddTraining';
import MailsOverview from '../mails/Mails';
import TrainersOverview from '../Trainers/TrainersOverview';
import LocationsGrid from '../locationsGrid/LocationsGrid';
import MetricDashboard from '../metricDashboard/MetricDashboard';
import MailEditor from '../mailEditor/MailEditor';
import Survey from '../surveys/Survey';
import Certificates from '../certificates/Certificates';
// import IndividualCertificate from '../individualCertificate/IndividualCertificate';
import WithSidebarAndHeader from '../../WithSidebarAndHeader';
import CertificatesList from '../certificates/CertificatesList';
import CertificateTemplatesOverview from '../certificates/CertificateTemplatesOverview';
import Test from '../test.js/Test';
import FillSurveyLink from '../surveys/FillSurveyLink/FillSurveyLink';
import UserManagement from '../userManagement/UserManagement';
import MailTraffic from '../mailTraffic/MailTraffic';
import Dashboard from '../dashboard/Dashboard';
import IndividualSession from '../IndividualSession/IndividualSession';
import SessionsOverview from '../sessionsOverview/SessionsOverview';
import IndividualTraining from '../IndividualTrainingFinal/IndividiualTraining';
import AddNewTraining from '../AddNewTraining/AddTraining';
import Dashboard2 from '../Dashboard2/Dashboard'; 

const MainContent = ({ authenticated, appState, setAppState  }) => {

    const { tenant } = appState;

    const rendering = () => {
        return (
            <MainContentSC>
                <Router>
                    <Routes>
                        <Route exact path="/*" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <Dashboard2 />
                            </WithSidebarAndHeader>
                        } />
                        <Route exact path="/trainings" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <TrainingsOverview />
                            </WithSidebarAndHeader>
                        } />

                        <Route exact path="/add-training" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <AddNewTraining />
                            </WithSidebarAndHeader>
                        } />

                        <Route path="/trainings/:trainingId/*" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <IndividualTraining/>
                            </WithSidebarAndHeader>
                        } />

                        {/* <Route path="/trainings/:id/:trainingId" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <IndividualTraining />
                            </WithSidebarAndHeader>
                        } /> */}

                        <Route path="/sessions/" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <SessionsOverview />
                            </WithSidebarAndHeader>
                        } />
                        <Route exact path="/sessions/:instanceId/*" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <IndividualSession />
                            </WithSidebarAndHeader>
                        } />



                        <Route exact path="/add-training" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <AddTraining />
                            </WithSidebarAndHeader>
                        } />

                        <Route path="/trainers" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <TrainersOverview />
                            </WithSidebarAndHeader>
                        } />

                        <Route path="/locations" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <LocationsGrid />
                            </WithSidebarAndHeader>
                        } />
{/* 
                        <Route exact path="/metrics" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <MetricDashboard />
                            </WithSidebarAndHeader>
                        } /> */}

                        <Route path="/mail-editor" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <MailEditor />
                            </WithSidebarAndHeader>
                        } />

                        <Route path="/certificates" element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <CertificatesList />
                            </WithSidebarAndHeader>
                        } />        

                        <Route path='/certificates/awarded' element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <CertificatesList />
                            </WithSidebarAndHeader>
                        }/>    

                        <Route path='/certificates/overview' element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <CertificateTemplatesOverview />
                            </WithSidebarAndHeader>
                        }/> 

                        <Route path='/user-management' element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <UserManagement />
                            </WithSidebarAndHeader>
                        }/>  

                        {/* <Route path='/mail-traffic' element={
                            <WithSidebarAndHeader authenticated={authenticated} appSate={appState} setAppState={setAppState}>
                                <MailTraffic />
                            </WithSidebarAndHeader>
                        }/>      */}

                        <Route path='/test' element={
                            <Test />
                        }/>         

                        <Route path="/sign-up/:trainingId/:instanceId" element={<SignUpForm />} />
                        {/* <Route path='/certificates/individual/:id' element={<IndividualCertificate />}/> */}
                        <Route path='/surveys/:surveyId/:instanceId/:trainingId' element={<Survey />}/>
                        <Route path='/fill-survey/:surveyId/:instanceId/:userId' element={<FillSurveyLink />}/>
                    </Routes>
                </Router>
            </MainContentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MainContentSC = styled.div`
    flex-grow : 1;
    overflow : hidden;
`

export default MainContent