import React from 'react';
import styled from 'styled-components';

const UICard = ({ children, styleTags}) => {

    const rendering = () => {
        return (
            <UICardSC style={styleTags}>
                {children}
            </UICardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UICardSC = styled.div`
    background-color : white;
    border-radius : 0px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.05);
    .title-component {
        display : flex; 
        padding : 20px;
        &>svg {
            max-width : 20px;
            max-height : 20px;
            margin-right : 5px;
        }
    }
`

export default UICard