import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import moment from 'moment'; 

const DateSelection = ({ setCurrentDate, currentDate}) => {

    const [datePicker, setDatePicker] = React.useState(false);
    const [yearDatePicker, setYearDatePicker] = React.useState(null);

    React.useEffect(() => {
        setYearDatePicker(moment(currentDate).format('YYYY'));
    }, [currentDate]);

    const jumpToNextMonth = (date) => {
        const newDate = moment(currentDate).add(1, 'months').startOf('month').toDate();
        setCurrentDate(newDate);
    };

    const jumpToPreviousMonth = (date) => {
        const newDate = moment(currentDate).subtract(1, 'months').startOf('month').toDate();
        setCurrentDate(newDate);
    };

    const jumpToNextYear = (date) => {
        const newDate = moment(yearDatePicker).add(1, 'years').startOf('year').toDate();
        setYearDatePicker(moment(newDate).format('YYYY'));
    };

    const jumpToPreviousYear = (date) => {
        const newDate = moment(yearDatePicker).subtract(1, 'years').startOf('year').toDate();
        setYearDatePicker(moment(newDate).format('YYYY'));
    };

    const selectDate = (month) => {
        const date = moment(`${yearDatePicker}-${month}-01`, 'YYYY-MMMM-DD').startOf('month').toDate();
        setCurrentDate(date);
        setDatePicker(false);
    };

    const rendering = () => {
        return (
            <DateSelectionSC>
                {datePicker && 
                    <div className='date-picker'>
                        <div className='years'>
                            <NavigateBeforeIcon onClick={() => jumpToPreviousYear(yearDatePicker)}/>
                                <p style={{ color : 'black'}}>{yearDatePicker}</p>
                            <NavigateNextIcon onClick={() => jumpToNextYear(yearDatePicker)}/>
                        </div>
                        <div className='months'>
                            {moment.months().map((month, index) => (
                                <p 
                                    className='month' 
                                    key={index}
                                    onClick={() => selectDate(month)}    
                                >{month}</p>
                            ))}
                        </div>
                    </div>}



                <div className='year-month-selector' onClick={() => setDatePicker(true)}>
                    <h2>{`${moment(currentDate).format('MMMM')} ${moment(currentDate).format('YYYY')}`}</h2>
                    <ArrowDropDownIcon />
                </div>
                <div className='previous-next'>
                    <NavigateBeforeIcon onClick={() => jumpToPreviousMonth()}/>
                    <NavigateNextIcon onClick={() => jumpToNextMonth()}/>
                </div>
                <div className='todays-date'>
                    <h2>today</h2>
                </div>
            </DateSelectionSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DateSelectionSC = styled.div`
    position : absolute; 
    right : 20px; 
    top : 20px;
    display : flex; 
    color: grey;
    z-index : 100000;

    .year-month-selector {
        border : 1px solid grey;
        padding : 5px 30px;
        border-radius : 5px;
        display : flex;
        align-items : center;
        justify-content : space-between;
        position : relative; 
    }

    .todays-date {
        border : 1px solid grey;
        padding : 5px 10px;
        border-radius : 5px;
        display : flex;
        align-items : center;
        justify-content : space-between;
        margin-left : 10px;
    }

    .previous-next {
        border : 1px solid grey;
        border-radius : 5px;
        display : flex;
        align-items : center;
        justify-content : space-between;
        margin-left : 10px;

        &>svg {
            padding : 5px;
            cursor : pointer;
            border-right : 1px solid grey;
        
            &:last-child {
                border-right : none;
            }
        
        }

    }

    .date-picker {
        position : absolute; 
        background-color : white; 
        z-index : 100;
        border : 1px solid grey;
        padding : 10px;
        min-width : 200px;

        .years {
            display : flex; 
            align-items : center;
            justify-content : space-between;
        }

        .months {
            display : flex; 
            flex-direction : column;


            .month {
                padding : 5px;
                cursor : pointer;

                &:hover {
                    background-color : #f1f1f1;
                    
                }
            }
        }
    }

`

export default DateSelection