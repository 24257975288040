import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Instances from './Instances';
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

const CalendarGrid = ({ days, instances, currentDate }) => {


    const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
    const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

    const instancesCurrentMonth = instances.filter(instance => 
        instance.dates.some(date => 
            moment(date).isSame(currentDate, 'month')
        )
    );

    React.useEffect(() => {
        const scrollToStart = () => {
            if(ref.current) {
                ref.current.scrollTo(0, 0);
            }
        }

        scrollToStart();
    }, [currentDate]);


    const rendering = () => {
        return (
            <CalendarGridSC ref={ref} {...events} >
                <div className='inner-container'>
                    {days.map((day, index) => {

                        const isWeekend = moment(day).format('dddd') === 'Saturday' || moment(day).format('dddd') === 'Sunday';
                        const isToday = moment(day).isSame(moment(), 'day');

                        return (
                            <div 
                                className={`date-column ${isWeekend ? 'weekend' : ''}`} 
                                key={index}>                     
                                <p>{`${moment(day).format('dddd')[0]}${moment(day).format('DD')}`}</p>
                                {isToday && 
                                    <div className='today-marker'>
                                        <p>today</p>
                                        <div className='today-line'/>
                                    </div>    
                                    }
                            </div>
                        )
                    })}
                    <Instances 
                        instancesCurrentMonth={instancesCurrentMonth}
                        days={days}    
                    />

                </div>
            </CalendarGridSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CalendarGridSC = styled.div`
    flex : 1;
    background-color : white;
    margin : 20px;
    display : flex; 
    overflow-x: scroll; /* Enable horizontal scrolling */
    cursor: grab;
    user-select: none;

    &:active {
        cursor: grabbing;
    }

    .date-column {
        min-width : 50px;
        max-width : 50px;
        display : flex; 
        justify-content : center;
        background-image: linear-gradient(#b0b0b0 33%, rgba(255,255,255,0) 0%);
        background-position: right;
        background-size: 1px 5px;
        background-repeat: repeat-y;
        border-spacing: 20px;
        position : relative; 

        .today-marker {
            position : absolute; 
            height : 100%;
            display : flex; 
            flex-direction : column;
            align-items : center;
            
            p {
                color : white; 
                background-color : #009DFF;
                padding : 5px;
                margin-top : 5px;
                border-radius : 5px;
            }

            .today-line {
                height : 100%;
                width : 3px;
                background-color : #009DFF;
            }
        }
        
        p {
            padding-top : 10px;
            font-size : 12px;
            color: #727272;
        }
    }

    .weekend {
        background-color : #fafafa;
    }

    .inner-container {
        display : flex; 
        flex-direction : row;
        position : relative; 
    }

    .testing {
        position : absolute;
        background-color : red; 
        min-height : 100%;
        min-width : 100%;
    }

`

export default CalendarGrid