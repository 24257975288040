import { Button, TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const SaveBar = ({ updateTitle, saveDesignFunction, titleTemplateRef, state }) => {

    console.log('state.designToLoad', state.designToLoad);

    const rendering = () => {
        return (
            <SaveBarSC>
                    <input 
                        type='text' 
                        defaultValue={state.designToLoad ? state.designToLoad.title : document.getElementById('title-template').value}
                        placeholder=''
                        ref={titleTemplateRef}    
                        id='title-template-save-bar'
                        autoComplete='off'
                    />
                    {/* <p>{titleTemplateRef.current.value}</p> */}
                    <Button
                        variant='contained'
                        color='primary' 
                        onClick={saveDesignFunction}
                    >
                        Save
                    </Button>
            </SaveBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SaveBarSC = styled.div`
    padding : 10px;
    display : flex;
    border-top : 1px solid lightgrey;
    justify-content : space-between;

    input {
        flex-grow : 1;
        min-width : 0;
        font-size : inherit;
        font-family : inherit; 
        padding : 10px;
        box-sizing : border-box;
        outline : none;
        border : none;
    }

    p {
        align-items : center;
        display : flex;
        padding-left : 15px;
    }
`

export default SaveBar