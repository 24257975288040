export const colors = {
    white : '#ffffff',
    primary : '#2C2C2C',
    secondary : '#037A84',
    tertiary : '#0000ff',

    lightGrey : '#90A0AD',
    midGrey : '#90A0AD',
    darkGrey : '#3C424D',

    strokeColor : "#E6E6E6",
    primaryBlue : '#009DFF'
}