import React , { useRef } from 'react';
import { useDraggable } from "react-use-draggable-scroll";
import styled from 'styled-components';
import TouchPointTile from './TouchPointTile/TouchPointTile';
import { MailScheduleBothLocationsContext } from './MailScheduleBothLocations';
import { v4 as uuidv4 } from 'uuid';

const ScheduledEmails = ({ touchPoints }) => {

    const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
    const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

    const touchPointsScheduledBeforeTraining = touchPoints.filter(touchPoint => touchPoint.beforeOrAfter === 'before');
    const touchPointsScheduledAfterTraining = touchPoints.filter(touchPoint => touchPoint.beforeOrAfter === 'after');

    const { addTouchPoint, setIndividualTouchPoint } = React.useContext(MailScheduleBothLocationsContext);

    const clickFunction = (touchPoint, trigger, beforeOrAfter) => {
        if(touchPoint) {
            setIndividualTouchPoint(touchPoint);
        } else {
            setIndividualTouchPoint({
                touchPointId: uuidv4(),
                name: '',
                timingMode: 'Scheduled',
                selectedTrigger: null,
                beforeOrAfter,
                mailTemplate: null,
                time: new Date().setHours(10, 0, 0, 0)
            });
        }
    }
    const rendering = () => {
        return (
            <ScheduledEmailsSC>
                <h2>Schedule e-mails</h2>
                <p className='description'>e-mails that are automatically sent before and after the training</p>

                <div className='mail-tiles' ref={ref} {...events}>
                    {touchPointsScheduledBeforeTraining.length === 0 &&                     
                        <div class="line">
                            <div class="circle" onClick={() => clickFunction(null, 'none', 'before')}>+</div>
                        </div>
                    }
                    {touchPointsScheduledBeforeTraining.map(touchPoint => {    
                        return (
                            <>
                                <TouchPointTile
                                    title={touchPoint.name}
                                    description={`User receives this e-mail ${touchPoint.name} days before the course starts`}
                                    template={touchPoint.mailTemplate}
                                    enabled={true}
                                    touchPoint={touchPoint}µ
                                    clickFunction={() => clickFunction(touchPoint, 'none', 'before')}
                                />
                                <div class="line">
                                    <div class="circle"
                                        onClick={() => clickFunction(null, 'none', 'before')}
                                    >+</div>
                                </div>
                            </>
                        );
                    })}
                    <div className='training-circle'>
                        <p>training</p>
                    </div>
                        <div class="line">
                            <div class="circle"
                                onClick={() => clickFunction(null, 'none', 'after')}
                            >+</div>
                        </div>
                    {touchPointsScheduledAfterTraining.map(touchPoint => {    
                        return (
                            <>

                                <TouchPointTile
                                    title={touchPoint.name}
                                    description={`User receives this e-mail ${touchPoint.name} days before the course starts`}
                                    template={touchPoint.mailTemplate}
                                    enabled={true}
                                    touchPoint={touchPoint}
                                    clickFunction={() => clickFunction(touchPoint, 'none')}

                                />
                                <div class="line">
                                    <div class="circle"
                                        onClick={() => clickFunction(null, 'none', 'after')}
                                    >+</div>
                                </div>
                            </>
                        );
                    })}
                </div>

            </ScheduledEmailsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ScheduledEmailsSC = styled.div`
    margin : 20px;

    h2 {
        font-size : 1.3rem;
        font-weight : 300;
        font-family : inherit;
        margin : 0;
    }

    .description {
        margin-bottom : 20px;
        font-size : 0.9rem;
        font-weight : 300;
    }

    .mail-tiles {
        display : flex;
        align-items : center;
        overflow-x : scroll;
        cursor : grab;
        user-select : none;
    }

    .training-circle {
        min-width : 100px;
        height : 100px;
        border-radius : 50%;
        background-color : white;
        display : flex;
        justify-content : center;
        align-items : center;
        font-size : 1.2rem;
        font-weight : 300;
        font-family : inherit;
        border : 1px solid #ccc;
    }

    .line {
        height : 1px;
        min-width : 50px;
        background-color : #ccc;
        position : relative;

        .circle {
            position : absolute;
            height : 30px;
            width : 30px;
            background-color : white;   
            border : 1px solid #ccc;
            border-radius : 50%;
            left : 10px;
            top : -15px;
            display : flex;
            justify-content : center;
            align-items : center;
            color : grey;

            &:hover {
                cursor : pointer;
                background-color : #F6F8FA;
            }
        }
    }
`

export default ScheduledEmails