import React from 'react';
import styled from 'styled-components';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import DateSelection from './DateSelection';
import CalendarGrid from './CalendarGrid';
import moment from 'moment';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';

const TrainingCalendar = () => {

    const [currentDate, setCurrentDate] = React.useState(moment().startOf('month').toDate())
    const [days, setDays] = React.useState([]);
    const [instances, setInstances] = React.useState([]);

    const getDaysInMonth = (date) => {
        const startOfMonth = moment(date).startOf('month');
        const endOfMonth = moment(date).endOf('month');
        const days = [];

        let day = startOfMonth;

        while (day <= endOfMonth) {
            days.push(day.toDate());
            day = day.clone().add(1, 'day');
        }

        return days;
    };
    
    React.useEffect(() => {
        const getTrainings = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/`);
                setInstances(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }

        getTrainings();
    }, []);

    React.useEffect(() => {
        setDays(getDaysInMonth(currentDate));
    }, [currentDate]);

    const rendering = () => {
        return (
            <UICard styleTags={{ margin : "20px", marginRight :0}}>
                <TrainingCalendarSC>
                    <div className='title-component' style={{ padding : '20px', paddingBottom : '20px'}}>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Training calendar</h1>
                    </div>
                    <DateSelection 
                        setCurrentDate={setCurrentDate}
                        currentDate={currentDate}                    
                    />
                    <CalendarGrid 
                        days={days}
                        currentDate={currentDate}
                        instances={instances}
                    />
                </TrainingCalendarSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingCalendarSC = styled.div`
    position : relative; 
    min-height : 400px;
    display : flex;
    flex-direction : column;
`

export default TrainingCalendar