export const navigationItems = [
    {
        name : 'Overview',
        path : 'overview'
    },
    {
        name : 'Participants',
        path : 'participants'
    },
    {
        name : 'Schedule',
        path : 'schedule'
    },
    {
        name : 'Survey',
        path : 'survey'
    }
]