import React from 'react';
import styled from 'styled-components';
import Header from './Header/Header';
import Axios from 'axios' ; 
import { backEndAdress } from '../../variables/back-endAdress';
import Navigation from './Navigation';
import { Route, HashRouter as Router, Routes  } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Exercises from './Exercises/Exercises';
import SendOutSurveyModal from '../IndividualTraining/SendOutSurveyModal/SendOutSurveyModal';
import SubNavigation from '../GeneralComponents/navigation/SubNavigation';
import { navigationItems } from './NavigationItems';

export const IndividualSessionContext = React.createContext();

const IndividualSession = () => {

    const [individualSession, setIndividualSession] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [individualTraining, setIndividualTraining] = React.useState({});
    const [jobPreview, setJobPreview] = React.useState(null);
    const [surveyModal, toggleSurveyModal] = React.useState(false);
    const [state, setState] = React.useState({
        selectedForSurvey : [],
        sendSurveyLoading : false,
    });

    const instanceIdFromURL = window.location.hash.split('/')[2];

    const sendOutSurvey = () => {

        const sendOutSurveyFunction = async () => {

            setState({...state, sendSurveyLoading : true});

            try {
                // For each attendee in the list perform the sendSurveysFunction
                const promises = state.selectedForSurvey.map(async (attendeeId) => {

                    console.log('attendeeId:', attendeeId);

                    const surveyLink = `http://localhost:3000/#/fill-survey/${individualTraining.surveyId}/${individualSession._id}/${attendeeId._id}`;
            
                    const handleBars = {
                        first_name: attendeeId.firstName.charAt(0).toUpperCase() + attendeeId.firstName.slice(1),
                        survey_link: surveyLink
                    };

                    const sendSurveysFunction = async () => {
                        try {
                            const response = await Axios.post(`${backEndAdress}/api/instances/send-out-surveys`, { 
                                handleBars: handleBars, 
                                attendee: attendeeId,
                                instance: individualSession                      
                            });
                            return response; 
                        } catch (error) {
                            return error;
                        }
                    };

                    // Return the promise so we can wait for it
                    return sendSurveysFunction();
                });
        
            // Wait for all promises to complete
            await Promise.all(promises);
            
            const updatedInstance = await Axios.get(`${backEndAdress}/api/instances/${individualSession._id}`);
            setIndividualSession(updatedInstance.data);
            setState({...state, selectedForSurvey: [], sendSurveyLoading: false});
        
            } catch (error) {
                console.error('Error processing surveys:', error);
            }
        }
    
        sendOutSurveyFunction();

    };


    React.useEffect(() => {
        const fetchIndividualSession = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/${instanceIdFromURL}`)
                setIndividualSession(response.data);
                return response; 
            } catch (error) {
                console.log(error);
            }
        }

        const fetchIndividualTraining = async (trainingId) => {   
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings/${trainingId}`)
                setIndividualTraining(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const runFunction = async () => {
            const instance = await fetchIndividualSession();
            if(instance) await fetchIndividualTraining(instance.data.trainingId);
            setLoading(false);
        }

        runFunction();

    }, []);

    const rendering = () => {
        return (
            !loading && 
                <IndividualSessionContext.Provider value={{
                    individualSession, 
                    setIndividualSession, 
                    individualTraining, 
                    setIndividualTraining,
                    jobPreview,
                    setJobPreview,
                    surveyModal, 
                    toggleSurveyModal,
                    sendOutSurvey
                }}>
                    <IndividualSessionSC>

                        {surveyModal && 
                            <SendOutSurveyModal
                                instance={individualSession}
                                state={state}
                                setState={setState}
                            />
                        }
                            <SubNavigation navigationItems={navigationItems}/>
                            <Routes>
                                <Route exact path="/overview" element={
                                    <Dashboard individualSession={individualSession}/>
                                } />
                                <Route exact path="/exercises" element={
                                    <Exercises />
                                } />
                            </Routes>               
                    </IndividualSessionSC>
            </IndividualSessionContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualSessionSC = styled.div`
    flex : 1;
    display : flex;
    flex-direction : column;
    overflow-y : scroll;

`

export default IndividualSession