import React from 'react';
import styled from 'styled-components';
import TouchPointTile from './TouchPointTile/TouchPointTile';
import { MailScheduleBothLocationsContext } from './MailScheduleBothLocations';
import { v4 as uuidv4 } from 'uuid';

const TriggerBasedEmails = ({ touchPoints, setTouchPointsLocal }) => {

    const { setIndividualTouchPoint } = React.useContext(MailScheduleBothLocationsContext);

    const clickFunction = (touchPoint, trigger) => {
        if(touchPoint) {
            setIndividualTouchPoint(touchPoint);
        } else {
            setIndividualTouchPoint({
                touchPointId : uuidv4(),
                selectedTrigger : trigger,
                name : trigger
            })
        }
    }
    
    const rendering = () => {

        const signUpConfirmationMail = touchPoints.filter(touchPoint => touchPoint.selectedTrigger === 'sign-up')[0];   
        const cancellationMail = touchPoints.filter(touchPoint => touchPoint.selectedTrigger === 'cancel')[0];   

        return (
            <TriggerBasedEmailsSC>
                <h2>Trigger based e-mails</h2>
                <p className='description'>e-mails that are sent when the user completes an action</p>
                <div className='mail-tiles'>
                    <TouchPointTile
                        title={'On sign-up'}
                        description={'User receives this e-mail when signing up for the course'}
                        template={signUpConfirmationMail?.mailTemplate}
                        enabled={true}
                        touchPoint={signUpConfirmationMail}
                        clickFunction={() => clickFunction(signUpConfirmationMail, 'sign-up')}
                    />
                    <TouchPointTile
                        title={'On cancel'}
                        description={'User receives this e-mail when the course is cancelled'}
                        template={cancellationMail?.mailTemplate}
                        enabled={true}
                        touchPoint={cancellationMail}
                        clickFunction={() => clickFunction(cancellationMail, 'cancel')}

                    />
                </div>
            </TriggerBasedEmailsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TriggerBasedEmailsSC = styled.div`
    padding : 20px;
    h2 {
        font-size : 1.3rem;
        font-weight : 300;
        font-family : inherit;
        margin : 0;
    }

    .description {
        margin-bottom : 20px;
        font-size : 0.9rem;
        font-weight : 300;
    }

    .mail-tiles {
        display : flex;
        gap : 10px;
    }

`

export default TriggerBasedEmails