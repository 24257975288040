import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Certificate from './Certificate/Certificate';
import Survey from './Survey/Survey';
import MailSchedule from './MailSchedule/MailSchedule';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import TrainingOverview from './TrainingOverview/TrainingOverview';
import EditTraining from './EditMode/EditTraining';
import Actions from './Actions/Actions';
import SendOutSurveyModal from '../IndividualTraining/SendOutSurveyModal/SendOutSurveyModal';
import SubNavigation from '../GeneralComponents/navigation/SubNavigation';
import { navigationItems } from './navigationItems';
import Overview from './Overview/Overview';
import { Route, HashRouter as Router, Routes  } from 'react-router-dom';
import IndividualSession from '../IndividualSession/IndividualSession';

export const IndividualTrainingContext = React.createContext();

const IndividualTraining = () => {
    const trainingId = window.location.hash.split('/')[2];

    const [individualTraining, setIndividualTraining] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    const [addInstanceToggled, setAddInstanceToggled] = React.useState(false);

    const [state, setState] = React.useState({
        trainingToEdit : null,
    });


    React.useEffect(() => {
        const getTraining = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings/${trainingId}`);
                setIndividualTraining(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }
        getTraining();
    }, []);

    const rendering = () => {
        if(individualTraining === null) {
            return <div>loading...</div>
        }
        
        return (
            <IndividualTrainingContext.Provider 
                value={{
                    individualTraining,
                    setEditMode,
                    state,
                    setState,
                    addInstanceToggled,
                    setAddInstanceToggled
                }}>

                {!state.trainingToEdit ? 
                    <IndividualTrainingSC>
                        <SubNavigation
                            navigationItems={navigationItems}
                        />
                        <Routes>
                            <Route path='/overview' element={<Overview />} />
                        </Routes>
                    </IndividualTrainingSC>
                : 
                    <EditTrainingSC>
                        <EditTraining 
                            state={state} 
                            setState={setState}
                            editExistingTraining={true}
                        />
                    </EditTrainingSC>
                }
            </IndividualTrainingContext.Provider>
        );

    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTrainingSC = styled.div`
    display : flex; 
    flex-direction : column;
    flex : 1;
    overflow : hidden;


`

const EditTrainingSC = styled.div`
    overflow-y:  hidden;
    flex : 1;
    display : flex;

`

export default IndividualTraining