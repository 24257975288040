import React from 'react';
import styled from 'styled-components';
import UICard from '../../../GeneralComponents/UIContainer/UICard';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';

const CustomProperties = ({ individualTraining }) => {

    const { name, description, customProperties, pricePerDay, numberOfDays } = individualTraining;

    const rendering = () => {
        return (
            <UICard styleTags={{ margin : '20px', marginTop : 0}}>
                <CustomPropertiesSC>
                    <div className='title-component'>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Upcoming trainings</h1>
                    </div>

                    <div className='boolean-properties'>
                        <div className='property-boolean'>
                            {customProperties.free ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                            <h1 className='property-name'>Free</h1>
                        </div>
                        <div className='property-boolean'>
                            {customProperties.onDemand ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                            <h1 className='property-name'>On demand</h1>
                        </div>
                        <div className='property-boolean'>
                            {customProperties.webinar ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                            <h1 className='property-name'>Webinar</h1>
                        </div>
                    </div>

                    <div className='availabilitie-properties'>
                        <div className='availability-property'>
                            <h1 className='property-name'>Available languages</h1>
                            {customProperties.availableLanguages.map((language, index) => {
                                return <h4 key={index} className='property-value'>{language}</h4>
                            })}
                        </div>
                        <div className='availability-property'>
                            <h1 className='property-name'>Available languages</h1>
                            {customProperties.availableLanguages.map((language, index) => {
                                return <h4 key={index} className='property-value'>{language}</h4>
                            })}
                        </div>
                    </div>

                    <div className='basic-properties'>
                        <div className='basic-property-item'>
                            <AspectRatioOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}} />
                            <h1 className='property-name'>Difficulty</h1>
                            <h2 className='property-value'>{customProperties.difficulty}</h2>
                        </div>
                        <div className='basic-property-item'>
                            <AspectRatioOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}} />
                            <h1 className='property-name'>Price</h1>
                            <h2 className='property-value'>{pricePerDay}</h2>
                        </div>
                        <div className='basic-property-item'>
                            <AspectRatioOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}} />
                            <h1 className='property-name'>Category</h1>
                            <h2 className='property-value'>{customProperties.category}</h2>
                        </div>
                        <div className='basic-property-item'>
                            <AspectRatioOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}} />
                            <h1 className='property-name'>Days</h1>
                            <h2 className='property-value'>{numberOfDays}</h2>
                        </div>
                    </div>

                </CustomPropertiesSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CustomPropertiesSC = styled.div`
    min-width : 420px;


    .boolean-properties {
        padding-left  : 20px;
        
        .property-boolean {
            display : flex;
            align-items : center;
            margin-bottom : 10px;

            svg {
                margin-right : 10px;
                font-size : 0.94em;
            }
        }
    }

    .availabilitie-properties {
        padding  : 20px;
        display : flex;
        padding-top : 30px;

        .availability-property {
            margin-bottom : 10px;
            max-width : 50%;
            min-width : 50%;   

            .property-name {
                margin-bottom : 10px;
            }

            .property-value {
                font-size : 1em;
                margin-bottom : 5px;
            }
        }
    }

    .basic-properties {
        padding: 20px;
        padding-top : 0;
        display : grid; 
        grid-template-columns : 1fr 1fr 1fr;
        grid-template-rows : minmax(110px, auto);
        svg {
            font-size : 1.8em;
        }
    }

`

export default CustomProperties