import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import { useNavigate } from 'react-router-dom';

const UpcomingTrainingTile = ({ instance }) => {

    const navigate = useNavigate();

    const [trainer, setTrainer] = React.useState(null);

    React.useEffect(() => {
        const getTrainer = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers/${instance.trainer._id}`);
                setTrainer(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getTrainer();
    }, []);

    const capacity = instance.attendees.length / instance.capacity;

    const getStatusClass = () => {
        if (capacity < 0.5) {
            return 'status-red';
        } else if (capacity >= 0.5 && capacity < 0.8) {
            return 'status-amber';
        } else {
            return 'status-green';
        }
    }


    const rendering = () => {
        return trainer && (
            <UpcomingTrainingTileSC
                onClick={() => navigate(`/sessions/${instance._id}/overview`)}
            >
                <div className='training-info'>
                    <h1>{instance.trainingName}</h1>
                    <h2 className='start-date'>{moment(instance.startDate).format('ddd DD/MM/YYYY')}</h2>
                    <div className={`attendees ${getStatusClass()}`}>
                        {`${instance.attendees.length}/${instance.capacity}`}
                    </div>
                </div>
                <div className='trainer-and-location'>
                    <img src={trainer.imageUrl} alt='trainer'/>
                    <h2>{instance.locationType === 'our-location' ? instance.location.title : 'customer location'}</h2>
                </div>
            </UpcomingTrainingTileSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UpcomingTrainingTileSC = styled.div`
    border-bottom : 1px solid lightgrey;
    padding : 10px;
    margin-bottom : 10px;  
    overflow : hidden;
    position : relative; 
    min-height : 70px;

    &:hover {
        cursor : pointer; 
        background-color : rgba(0,0,0,0.05);
    }

    h1 {
        max-width : 80%;
        display: -webkit-box;        /* Use a flexible box layout */
        -webkit-box-orient: vertical; /* Make the box behave vertically */
        -webkit-line-clamp: 1;        /* Limit to 2 lines */
        overflow: hidden;            /* Hide overflowed text */
        text-overflow: ellipsis;     /* Add ellipsis at the end */
    }

    .attendees {
        margin-top : 10px;
        padding : 6px;
        font-size : 0.8em;
        width : 25px;
        border-radius : 5px;
        text-align : center;
    }

    .trainer-and-location {
        position : absolute;
        bottom : 15px;
        right : 15px;
        display : flex;
        flex-direction : column;
        font-size : 0.8em;
        color : grey;
        text-align : right;
        justify-content : flex-end;
        align-items : flex-end;

        display: -webkit-box;        /* Use a flexible box layout */
        -webkit-box-orient: vertical; /* Make the box behave vertically */

        
        &>img {
            width : 50px;
            height : 50px;
            border-radius : 50%;
            margin-bottom : 5px;
        }
    }

`

export default UpcomingTrainingTile