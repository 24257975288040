export const defaultMailSchedule = {   
        defaultTouchPointsArray : [
                {
                        touchPointId: "fadfdf83-82c5-45d3-9a6e-4a5fd6a0ee87",
                        name: "sign-up",
                        timingMode: "Trigger",
                        selectedTrigger: "sign-up",
                        numberOfDays: null,
                        beforeOrAfter: null,
                        time: null,
                        subject: "Confirmation sign-up",
                        sendAt: null,
                        mailTemplate: {
                                title: "Signup general",
                                description: "test",
                                mailTemplateId: "66cf56de88e8870fb3942436",
                                _id: "66cf56de88e8870fb3942436",
                                createdAt: "2024-10-10T15:12:55.262Z",
                                updatedAt: "2024-10-10T15:12:55.262Z"
                        },
                        _id: "6707edc245c5d9208e04ba4c",
                        createdAt: "2024-10-10T15:12:55.262Z",
                        updatedAt: "2024-10-10T15:12:55.262Z"
                },
                {
                        touchPointId: "e2a2bab8-bda3-496b-be2c-c2ba5ce0c3d7",
                        name: "14 days before",
                        timingMode: "Scheduled",
                        selectedTrigger: null,
                        numberOfDays: 14,
                        beforeOrAfter: "before",
                        time: "2024-10-10T08:00:29.712Z",
                        subject: "See you in 2 weeks!",
                        sendAt: {
                                minute: 0,
                                hour: 10,
                                day: 26,
                                month: 9,
                                year: 2024
                        },
                        mailTemplate: {
                                title: "2 Weeks out",
                                description: "test",
                                mailTemplateId: "66b3cc70aeef4570730855de",
                                _id: "66b3cc70aeef4570730855de",
                                createdAt: "2024-10-10T15:12:55.262Z",
                                updatedAt: "2024-10-10T15:12:55.262Z"
                        },
                        _id: "6707edc245c5d9208e04ba4e",
                        createdAt: "2024-10-10T15:12:55.262Z",
                        updatedAt: "2024-10-10T15:12:55.262Z"
                },
                {
                        touchPointId: "f4071334-a61d-40fe-8282-56bb35cfbff8",
                        name: "1 day before",
                        timingMode: "Scheduled",
                        selectedTrigger: null,
                        numberOfDays: 1,
                        beforeOrAfter: "before",
                        time: "2024-10-10T07:30:42.425Z",
                        subject: "See you tomorrow!!",
                        sendAt: {
                                minute: 30,
                                hour: 9,
                                day: 9,
                                month: 10,
                                year: 2024
                        },
                        mailTemplate: {
                                title: "See you tomorrow",
                                description: "test",
                                mailTemplateId: "66b3cca1aeef4570730855fa",
                                _id: "66b3cca1aeef4570730855fa",
                                createdAt: "2024-10-10T15:12:55.262Z",
                                updatedAt: "2024-10-10T15:12:55.262Z"
                        },
                        _id: "6707edc245c5d9208e04ba50",
                        createdAt: "2024-10-10T15:12:55.262Z",
                        updatedAt: "2024-10-10T15:12:55.262Z"
                },
                {
                        touchPointId: "a8ccd3e7-0448-463a-9365-58852386a8d5",
                        name: "cancel",
                        timingMode: "Trigger",
                        selectedTrigger: "cancel",
                        subject: "Your course has been cancelled",
                        mailTemplate: {
                                title: "Cancellation mail",
                                mailTemplateId: "6729ebf76cf8ea8b0455c724",
                                _id: "6729ebf76cf8ea8b0455c724",
                                createdAt: "2024-11-05T09:57:11.199Z",
                                updatedAt: "2024-11-05T09:57:11.199Z"
                        },
                        _id: "6756e11b554c7299da0f255f",
                        createdAt: "2024-12-09T12:22:51.948Z",
                        updatedAt: "2024-12-09T12:22:51.948Z"
                }
        ],
        customerLocationTouchPointsArray: [
                {
                        touchPointId: "6996f28e-c452-41e7-adfd-655b4ae290eb",
                        name: "cancel",
                        timingMode: "Trigger",
                        selectedTrigger: "cancel",
                        subject: "Your course has been cancelled",
                        mailTemplate: {
                        title: "Cancellation mail",
                        mailTemplateId: "6729ebf76cf8ea8b0455c724",
                        _id: "6729ebf76cf8ea8b0455c724",
                        createdAt: "2024-11-05T09:57:11.199Z",
                        updatedAt: "2024-11-05T09:57:11.199Z"
                        },
                        _id: "6756e11b554c7299da0f2561",
                        createdAt: "2024-12-09T12:22:51.948Z",
                        updatedAt: "2024-12-09T12:22:51.948Z"
                },
                {
                        touchPointId: "6d74ab98-a18a-42d4-b639-3de44446a209",
                        name: "sign-up",
                        timingMode: "Trigger",
                        selectedTrigger: "sign-up",
                        subject: "undefined",
                        mailTemplate: {
                        title: "Confirmation sign-up | users signed up by filling link",
                        mailTemplateId: "6717a3b130a68d1d1d8540ee",
                        _id: "6717a3b130a68d1d1d8540ee",
                        createdAt: "2024-10-22T13:08:01.133Z",
                        updatedAt: "2024-10-22T13:08:01.133Z"
                        },
                        _id: "6756e11b554c7299da0f2563",
                        createdAt: "2024-12-09T12:22:51.948Z",
                        updatedAt: "2024-12-09T12:22:51.948Z"
                }
        ],
}       