import React from 'react';
import styled from 'styled-components';
import AddOrEditTraining from '../addOrEditTrainingComponent/AddOrEditTraining';
import { v4 } from 'uuid';
import { defaultMailSchedule } from '../addTraining/defaultTouchPointsArray';
const AddTraining = () => {

    const [state, setState] = React.useState({
    });

    const newTraining = {
        name : '',
        description : '',
        defaultTouchPointsArray : defaultMailSchedule.defaultTouchPointsArray,
        customerLocationTouchPointsArray : defaultMailSchedule.customerLocationTouchPointsArray,
        customProperties: {
            availableLocations : [],
            availableLanguages : [],
            difficulty : '',
            onDemand : false, 
            webinar : false, 
            free : false
        },
        textBlocks : [{
            title : 'Add title', 
            content : '',
            textBlockId : v4()
        }]
    };

    const rendering = () => {
        return (
            <AddTrainingSC>
                <AddOrEditTraining
                    state={state}
                    setState={setState}
                    editExistingTraining={false}
                    trainingToEdit={newTraining}
                />
            </AddTrainingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTrainingSC = styled.div`
    flex : 1;
    display : flex;
`

export default AddTraining