import React from 'react';
import styled from 'styled-components';
import UpcomingTrainings from './UpcomingTraining/UpcomingTrainings';
import KPIRow from './KPIRow/KPIRow';
import RecentRegistrations from './RecentRegistrations/RecentRegistrations';
import TrainingCalendar from './TrainingCalendar/TrainingCalendar2';

const Dashboard = () => {

    const rendering = () => {
        return (
            <DashboardSC>
                <KPIRow />
                <div style={{display : 'flex'}}>
                    <UpcomingTrainings />
                    <div style={{ flexGrow : 1, display : 'flex', flexDirection : 'column', overflow: 'hidden'}}>
                        <TrainingCalendar />
                        {/* <RecentRegistrations /> */}
                    </div>  
                </div>

            </DashboardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardSC = styled.div`
    flex : 1;
    padding : 20px;
    flex-direction : column;
    display : flex; 
    

`

export default Dashboard