import React from 'react';
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Delete } from '@mui/icons-material';
import DeleteTemplatePopUp from './DeleteTemplatePopUp';
import { backEndAdress } from '../../../variables/back-endAdress';
import Axios from 'axios' ; 
import { AppContext } from '../../../App';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { colors } from '../../../variables/Colors';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const TemplatesGrid = ({
    mailTemplates,
    searchQuery,
    setMailTemplateForPreview,
    mailTemplate,
    templates,
    setTemplates
}) => {

    console.log('mailTemplates', mailTemplates);

    const [deletePopUp, setDeletePopUp] = React.useState(false); 

    const { appState } = React.useContext(AppContext);

    React.useEffect(() => {

        let templatesTemp = [];
        
        const allTemplatesWithFolder = mailTemplates.length>0 ? mailTemplates.filter((template) => template.folder) :[] ;
        const generalTemplates = mailTemplates.length>0 ? mailTemplates.filter((template) => !template.folder).map((template) => ({...template, folder : 'general'})) : [] ; 
        templatesTemp = [...allTemplatesWithFolder, ...generalTemplates];

        setTemplates(templatesTemp);

    },[mailTemplates])

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const deleteTemplateFunction = () => {
        const deleteTemplate = async () => {
            try {
                const response = await Axios.delete(`${backEndAdress}/api/mail-templates/${appState.tenant}/${mailTemplate._id}`);

                let templatesTemp = response.data ; 
                const allTemplatesWithFolder = templatesTemp.filter((template) => template.folder);
                const generalTemplates = templatesTemp.filter((template) => !template.folder).map((template) => ({...template, folder : 'general'})); 
                templatesTemp = [...allTemplatesWithFolder, ...generalTemplates];      
                setTemplates(templatesTemp);
                setDeletePopUp(false);

            } catch (error) {
                console.log(error);
            }
        }

        deleteTemplate();
    }

    const rendering = () => {
        
        if(mailTemplates) {
       
            const uniqueFolders = [...new Set(templates.map((template) => template.folder))];

            return (
                <TemplatesGridSC>

                    {/* {deletePopUp && 
                        <DeleteTemplatePopUp 
                            setDeletePopUp={setDeletePopUp} 
                            deleteTemplateFunction={deleteTemplateFunction}
                            handleClose={() => setDeletePopUp(false)}    
                    />} */}

                    {uniqueFolders.map((folder) =>{

                        const folderTemplates = templates.filter((template) => template.folder === folder).filter((template) => template.title.toLowerCase().includes(searchQuery.toLowerCase()));

                        return ( 
                            <>
                            <div key={folder}>
                                <div className='folder-title'>
                                    <FolderOpenIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                                    <h1>{capitalizeFirstLetter(folder)}</h1>
                                </div>
                                <div style={{ 
                                    display : 'flex', 
                                    flexDirection : 'column', 
                                    paddingTop : '15px', 
                                    paddingBottom : '15px', 
                                    alignItems : 'flex-start',
                                    maxWidth : '100%',
                                    overflow : 'ellipsis'
                                }}>
                                    {folderTemplates.map((template) => {

                                        const isCurrentlySelected = mailTemplate ? mailTemplate._id === template._id ? true : false : false;

                                        return (
                                            <div 
                                                key={template.id} 
                                                className={`template-name`} 
                                                onClick={() => setMailTemplateForPreview(template)}
                                            >
                                                <MailOutlineIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                                                <h2 
                                                    className={`${isCurrentlySelected && 'selected'}`}
                                                    style={{ color : 'black', fontWeight : '300'}}    
                                                >{capitalizeFirstLetter(template.title)}</h2>
                                                
                                                {/* {
                                                isCurrentlySelected && 
                                                    <div className='action-icons'>
                                                        <Delete 
                                                            className='delete-icon'
                                                            onClick={() => setDeletePopUp(true)}    
                                                        />
                                                        <CheckBoxIcon style={{marginLeft : '15px'}}/>
                                                    </div>
                                                } */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            </>
                        );
                    })}

                </TemplatesGridSC>
            );
        }

    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TemplatesGridSC = styled.div`
    flex-grow : 1;
    
    .folder-title {
        display : flex; 
        align-items : center;
        margin-left : 20px;

        &>svg {
            margin-right : 10px;
            color : ${colors.primaryBlue};

        }
    }

    .template-name {
        padding-bottom : 0;
        display : flex;
        padding : 5px;
        padding-left : 40px;
        align-items : center;
        min-width : 100%;
        
        h2 {
            max-width : 70%;
            font-size : 15px;
            display: -webkit-box;        /* Use a flexible box layout */
            -webkit-box-orient: vertical; /* Make the box behave vertically */
            -webkit-line-clamp: 1;        /* Limit to 2 lines */
            overflow: hidden;            /* Hide overflowed text */
            text-overflow: ellipsis;     /* Add ellipsis at the end */

        }

        &>svg {
            margin-right : 10px;
        }

        &:hover {
            cursor : pointer;
            font-weight : 400;
        }

        &:hover .action-icons .delete-icon {
            display : block;
        }

        .action-icons {
            display : flex;
            align-items : center;
            justify-content : center;
            padding-right : 10px;
        
            .delete-icon {
                display : none;
                color : lightgrey;

                &:hover {
                    color : grey;
                    cursor : pointer;
                }

            }


        }
    }
    
    .selected {
        background-color : #F9F9F9;
        border-radius : 5px;
        padding-left : 5px;
        margin-left : -5px;
        padding-top : 2px;
        padding-bottom : 2px;
    }

`

export default TemplatesGrid