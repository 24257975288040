import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { TrainersOverviewContext } from './TrainersOverview';
import UICard from '../GeneralComponents/UIContainer/UICard';

const TrainersGrid = () => {

    const { trainersState, setTrainersState, openModalNewTrainer} = React.useContext(TrainersOverviewContext);
    const { trainers } = trainersState;

    const rendering = () => {
        return (
            <TrainersGridSC>
                <div className='trainers-overview'>
                    {trainers.map((trainer, index) => {
                        return (
                            <UICard style={{ padding : '5px'}}>
                                <div 
                                    key={index} 
                                    className='trainer'
                                    onClick={() => setTrainersState({ ...trainersState, individualTrainer : trainer })}    
                                >
                                    <div className='background-image' style={{backgroundImage : `url(${trainer.imageUrl})`}}></div>
                                    <h3>{trainer.firstName} </h3>
                                </div>
                            </UICard>
                        )
                    })}
                </div>

                    <Button 
                        className='button-bottom-right' 
                        variant='contained' 
                        color='primary'
                        style={{ position : 'absolute', bottom : '20px', right : '20px' }}
                        onClick={() => openModalNewTrainer()}
                    >   
                        Add trainer
                    </Button>
            </TrainersGridSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainersGridSC = styled.div`
    flex-grow : 1;
    min-height : 100%;
    position : relative;

    .trainers-overview {
        display : grid;
        grid-template-columns : repeat(auto-fill, minmax(150px, 1fr));
        grid-gap : 20px;
        padding : 20px;
        position : relative;
    }

    .trainer {
        min-width : 100%;
        aspect-ratio : 1; /* Ensure height is equal to width */
        box-sizing : border-box;
        align-items : center;
        display : flex;
        flex-direction : column;
        padding : 10px;
        box-sizing : border-box;
        
        &:hover {
            cursor : pointer;
        }

        h3 {
            display: -webkit-box;        /* Use a flexible box layout */
            -webkit-box-orient: vertical; /* Make the box behave vertically */
            -webkit-line-clamp: 2;        /* Limit to 2 lines */
            overflow: hidden;            /* Hide overflowed text */
            text-overflow: ellipsis;     /* Add ellipsis at the end */
            max-width : 80%;
            font-size : 1.2rem;
            padding : 10px 0;
        }

    }

    .background-image {
        min-height : 100%;
        min-width : 100%;
        max-height : 100%;
        max-width : 100%;

        background-size : cover;
        background-position : center;
    }


`

export default TrainersGrid