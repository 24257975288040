import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { AddInstanceContext } from '../AddInstance';

const AddInstanceFooter = ({ closeFunction }) => {

    const { currentMenu, setCurrentMenu, closeIndividualTouchPoint, saveFunction, formRef } = React.useContext(AddInstanceContext);

    const submitFunction = (e) => {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }


    const rendering = () => {
        return (
            <AddInstanceFooterSC>
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => closeFunction() }
                >
                    Cancel
                </Button> 
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setCurrentMenu(currentMenu - 1)}
                    disabled={currentMenu === 1}
                >
                    Previous
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setCurrentMenu(currentMenu + 1)}
                    disabled={currentMenu === 2}
                >
                    Next
                </Button>
                
                {currentMenu === 2 && <Button
                    variant='contained'
                    color='primary'
                    onClick={(e) => submitFunction(e)}
                >
                    SAVE
                </Button>
                }

            </AddInstanceFooterSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddInstanceFooterSC = styled.div`
    padding : 20px;
    display : flex;
    justify-content : flex-end;
    min-height : 30px;
    border-top : 1px solid #e0e0e0;
    
    button {
        margin-left : 10px;
    }
`

export default AddInstanceFooter