import React, { useRef } from 'react';
import styled from 'styled-components';
import Modal from '../../../GeneralComponents/Modal';
import { Button } from '@mui/material';
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import TitleIcon from '@mui/icons-material/Title';
import AbcIcon from '@mui/icons-material/Abc';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Axios from 'axios';
import { backEndAdress } from '../../../../variables/back-endAdress';
import LoadingSpinner from '../../../GeneralComponents/LoadingSpinner';

const MailAllUsers = ({ setMailAllUsersModal, individualSession }) => {
    
    const [sendingMail, setSendingMail] = React.useState(false);
    const [mailSendComplete, setMailSendComplete] = React.useState(false);

    const subjectRef = useRef(null); 

    const editor = useEditor({
        extensions: [
            StarterKit,
            TextStyle
        ],
        content: '<p>Hello all.Just wanted to let you know. That the training is going to be LIT fam</p>'
    })

    const sendMailAllUsers = () => {

        const subject = subjectRef.current.value;
        const content = editor.getHTML();
        const attendees = individualSession.attendees.map(attendee => ({ email : attendee.email , name : `${attendee.firstName} ${attendee.lastName}`})); 

        const sendMailAllUsers = async () => {

            setSendingMail(true);
            
            try {
                await Axios.post(`${backEndAdress}/api/mails/send-all-users`, { subject, content, attendees, isntanceId : individualSession._id });
                setSendingMail(false);
                setMailSendComplete(true);
            } catch (error) {
                console.log(error);
            }
        }

        sendMailAllUsers();


    }

    const rendering = () => {
        return (
            <Modal>
                <MailAllUsersSC>

                {sendingMail && 
                    <div className='sending-mail'>
                        <LoadingSpinner/>
                        <h1  style={{ marginTop : '20px'}}>Sending mail</h1>
                        <p>Please wait</p>
                    </div>
                }

                {mailSendComplete && 
                    <div className='sending-mail'>
                        <h1  style={{ marginBottom : '20px'}}>Mail sent!</h1>
                        <Button 
                            variant='outlined' 
                            color='primary'
                            onClick={() => setMailAllUsersModal(false)}    
                        >Close</Button>
                    </div>
                }

                    <div className='top-bar'>
                        <div className='subject-container'>
                            <p>Subject</p>
                            <input type='text' ref={subjectRef}/>
                        </div> 
                        <Button variant='outlined' color='primary' onClick={() => setMailAllUsersModal(false)}>Close</Button>
                    </div>

                    <div className='tip-tap-container'>
                        <div className="editor-toolbar">
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    editor.chain().focus().toggleHeading({ level: 1 }).run()}
                                }
                                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                            >
                                <TitleIcon />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    editor.chain().focus().setParagraph().run()}
                                }
                                className={editor.isActive('paragraph') ? 'is-active' : ''}
                            >
                                <AbcIcon />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    editor.chain().focus().toggleBulletList().run()}
                                }
                                className={editor.isActive('bulletList') ? 'is-active' : ''}
                            >
                                <FormatListBulletedIcon />
                            </button>
                        </div>
                        <EditorContent 
                            editor={editor} 
                            className='editor-content'
                            spellCheck={false}    
                        />
                    </div>
                    <div className='send-bar'>
                        <Button variant='contained' color='primary' onClick={() => sendMailAllUsers()}>Send</Button>
                    </div>
                    
                </MailAllUsersSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailAllUsersSC = styled.div`
    background-color : white;
    min-width : 800px;
    min-height : 500px;    
    display : flex;
    flex-direction : column;
    position : relative; 

    .top-bar {
        display : flex;
        justify-content : space-between;
        padding : 10px;
        border-bottom : 1px solid lightgrey;
        box-sizing : border-box;
        align-items : center;
    }

    .subject-container {
        font-size : 14px;
        padding : 10px;
        border-top-left-radius : 5px;
        border-bottom-left-radius : 5px;
        display : flex; 
        align-items : center;
        box-sizing : border-box;

        p {
            margin-right : 15px;
        }

        input {
            background-color : white;
            color : black;
            font-size : 14px;
            padding : 10px;
            flex-grow : 1;
            border : 1px solid lightgrey;
            outline : none;
            border-radius : 5px;
            min-width : 300px;
            flex : 1;
        }
    }

    button {
        max-height : 45px;
    }

    .tip-tap-container {
        display : flex;
        flex-direction : column;
        flex-grow : 1;
        margin : 15px;
    }

    .editor-toolbar {
        display : flex;
        
        button {
            background-color : #fafafa;
            border : 1px solid #ddd;
            padding : 8px;
            cursor : pointer;
            display : flex;
            justify-content : center;
            align-items : center;
            color : grey;

            &.is-active {
                background-color : #f0f0f0;
            }
        }
    }

    .editor-content {
        border: 1px solid #ddd;
        padding: 25px;
        border-radius: 8px;
        flex-grow : 1;
        max-height : 350px;
        overflow-y : auto;
        min-height : 350px;
        
        .tiptap {
            outline : none;
            flex-grow : 1;

            h1 {
                color : #D20A4B;
                margin : 0;
                font-family : 'Outfit', sans-serif;
                font-weight : 400;
                font-size : 1.2rem;
            }

            p {
                margin : 0;
                color : #1C4050;
            }
        }

        &>h1 {
            color : red;
        }

    }

    .send-bar {
        display : flex; 
        justify-content : flex-end;
        padding : 10px;
        padding-top : 0;
    }

    .sending-mail {
        background-color : white;
        display : flex; 
        flex-direction : column;
        z-index : 1000;
        position : absolute; 
        margin : 0 auto;
        top : 50%;
        left : 40%;
        border : 1px solid lightgrey;
        border-radius : 5px;
        align-items : center;
        padding : 35px;
    }
`

export default MailAllUsers