import React from 'react';
import styled from 'styled-components';
import TrainingBar from './TrainingBar';

const Instances = ({ instancesCurrentMonth, days }) => {

    const uniqueTrainers = [...new Set(instancesCurrentMonth.map(instance => instance.trainer.firstName))];
    
    const rendering = () => {
        return (
            <InstancesSC>
                {uniqueTrainers.map((trainer, index) => {


                    const trainerInstances = instancesCurrentMonth.filter(instance => instance.trainer.firstName === trainer);
                    
                    return (
                        <div className='trainer-row'>
                            {
                                trainerInstances.map((instance) => {
                                    return (
                                        <TrainingBar 
                                            instance={instance}
                                            days={days}
                                            trainer={trainer}
                                        />
                                    );
                            })}
                        </div>
                    );
                })}
            </InstancesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstancesSC = styled.div`
    flex : 1;
    z-index : 2400;
    position : absolute; 
    top : 46px;
    left : 0;
    right : 0; 
    bottom : 0;
    display : flex; 
    flex-direction : column;    

    .trainer-row {
        position : relative; 
        border-bottom : 1px dotted lightgrey;
        border-bottom-style: dotted;
        border-bottom-width: 1px;
        border-bottom-color: lightgrey;
        min-height : 46px;
    }

`

export default Instances