import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import InstancesList from './InstancesList';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import WorkshopsList from '../GeneralComponents/instancesList/WorkshopsList';
import Axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { backEndAdress } from '../../variables/back-endAdress';

const PreOpenModal = ({ preOpenTraing, state, setState }) => {

    const navigate = useNavigate(); 

    const linkToTraining = (data) => {
        const { _id, trainingId } = data;
        navigate(`/sessions/${_id}`);
    };

    const deleteTraining = async () => {
        try {
            const response = await Axios.delete(`${backEndAdress}/api/trainings/${preOpenTraing._id}`);
            alert('deleted training'); 
        } catch (error) {
            console.log('error : ', error);
        }
    }

    const rendering = () => {
        return (
            <Modal>
                <PreOpenModalSC>
                    <div className='modal-header'>
                        <p className='training-title'>{preOpenTraing.name}</p>
                        <div className='buttons'>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={() => setState({...state, trainingToEdit : preOpenTraing})}
                            >
                                edit training
                            </Button>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={() => setState({
                                    ...state, 
                                    addInstanceToggled : true?
                                    preOpenTraing : null    
                                })}
                            >
                                Schedule workshop
                            </Button>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={() => deleteTraining()}
                            >
                                Delete
                            </Button>
                            <Button 
                                variant='outlined'
                                color='primary'
                                onClick={() => setState({...state, preOpenTraing : null})}
                            >
                                close
                            </Button>

                            {/* <div className='button close-button' onClick={() => setState({...state, preOpenTraing : null})}>close</div>
                            <div className='button open-training-button'>edit training</div>
                            
                            <div 
                                className='button open-training-button' 
                                onClick={() => setState({
                                    ...state, 
                                    addInstanceToggled : true?
                                    preOpenTraing : null    
                                })}>Create new intance</div> */}
                        </div>
                    </div>

                    {/* <InstancesList 
                        onClickFunction={linkToTraining} 
                        preOpenTraing={preOpenTraing}
                    /> */}

                    <WorkshopsList
                        onClickFunction={linkToTraining}
                        preOpenTraing={preOpenTraing}
                    />

                    

                </PreOpenModalSC>
            </Modal>

        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PreOpenModalSC = styled.div`
    background-color : white;
    width : 80%;
    height : 80%;
    border-radius : 5px;
    display : flex; 
    flex-direction : column;
    padding : 15px;
    box-sizing : border-box;

    .modal-header {
        display : flex;
        justify-content : space-between;
    }

    .modal-header .training-title {
        padding : 15px;
        padding-left : 15px;
        padding-right : 15px;
        font-size : 1.5rem;
    }

    .modal-header .buttons {
        display : flex;
        padding : 15px;

        &>.button {
            padding : 10px;
            cursor : pointer;
            border : 1px solid black;
            margin-left : 10px;
            border-radius : 5px;
        }
    }

    .buttons {
        &>button {
            margin-left : 10px;
        }
    }
`

export default PreOpenModal