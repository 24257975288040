import React from 'react';
import styled from 'styled-components';
import TrainingBar from './TrainingBar';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress'; 
import { TrainingCalendarContext } from './TrainingCalendar';

const TrainingsPerTrainer = () => {

    const { currentMonth } = React.useContext(TrainingCalendarContext);
    const [instances, setInstances] = React.useState([]);

    React.useEffect(() => {
        const getTrainings = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/`);
                setInstances(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }

        getTrainings();
    }, []);


    const rendering = () => {

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const currentMonthIndex = monthNames.indexOf(currentMonth);
        const filteredInstances = instances.filter(instance => {
            return instance.dates.some(dateString => {
            const date = new Date(dateString);
            return date.getMonth() === currentMonthIndex;
            });
        });

        const trainers = filteredInstances.map(instance => instance.trainer);
        const uniqueTrainers = trainers.filter((trainer, index, self) =>
            index === self.findIndex((t) => (
                t.firstName === trainer.firstName
            ))
        );

        console.log('filteredInstances', filteredInstances);

        return (
            <TrainingsPerTrainerSC>
                {uniqueTrainers.map((trainer, index) => {
                    const trainerInstances = filteredInstances.filter(instance => instance.trainer.firstName === trainer.firstName);
                    
                    return (
                        <div className='trainer-row'>
                            {
                                trainerInstances.map((instance) => {
                                return (
                                    <TrainingBar 
                                        instance={instance}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            

            </TrainingsPerTrainerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingsPerTrainerSC = styled.div`
    padding-bottom : 10px;
    display : flex; 
    flex-direction : column;

    .trainer-row {
        min-width : 100%;
        align-items : center;
        position : relative;
        min-height : 40px;
        border-top : 1px solid #f0f0f0;

    }
`

export default TrainingsPerTrainer