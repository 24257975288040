import React from 'react';
import styled from 'styled-components';
import RegistrationItem from './RegistrationItem';
import Search from '../../GeneralComponents/Search';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import UICard from '../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
const RecentRegistrations = () => {

    const [registrations, setRegistrations] = React.useState([]);
    const [query, setQuery] = React.useState('');

    React.useEffect(() => {
        const getRegistrations = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/get-all-attendees-list`)
                setRegistrations(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getRegistrations();
    }, []);


    const filteredRegistrations = registrations ? registrations.filter(registration => `${registration.firstName} ${registration}`.toLowerCase().includes(query.toLowerCase())) : [];	


    const rendering = () => {
        return (
            <UICard styleTags={{ marginLeft : "20px"}}>
                <RecentRegistrationsSC>
                    <div className='title-component' style={{ padding : 0, paddingBottom : '20px' }}>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Recent registrations</h1>
                    </div>

                    <Search 
                        placeholder='Search registrations...'
                        style={{ marginBottom : '20px' }}
                        query={query}
                        setQuery={setQuery}
                    />
                    <div className='registrations-list'>
                        {filteredRegistrations.map(registration => {
                            return (
                                <RegistrationItem registration={registration} />
                            );
                        })}
                    </div>
                </RecentRegistrationsSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RecentRegistrationsSC = styled.div`
    background-color : white;
    border-radius : 10px;
    padding : 20px;
    flex-grow : 1;
    min-height : 500px;
    max-height : 700px;
`

export default RecentRegistrations