import { Avatar, Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { HeigtsPaddingBorderRadius } from '../../variables/HeigtsPaddingBorderRadius';
import { useAuth0 } from '@auth0/auth0-react';

const { headerFontSize } = HeigtsPaddingBorderRadius;

const Profile = () => {

    const { user, logout } = useAuth0();
    const [menu, openMenu] = React.useState(false);

    const capitalizeFirstLetter = (str) => {
        const test = str.split(' ').map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });

        return test.join(' ');
    }


    const rendering = () => {
        return (
            <ProfileSC>
                <Avatar 
                    alt="Remy Sharp" 
                    src={user.picture} 
                    style={{
                        height : '35px', 
                        width : '35px'
                    }} 
                />

                {menu &&
                    <div className='login-menu'>
                        <div className='menu-item'>
                            <p>{capitalizeFirstLetter(user.name)}</p>
                        </div>
                        <div className='menu-item'>
                            <p>Settings</p>
                        </div>
                        <div className='menu-item'>
                            <p>Help</p>
                        </div>
                        <div className='menu-item'>
                            <Button
                                variant='contained'
                                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                            >
                                Log out
                            </Button>
                        </div>
                    </div>
                }

            </ProfileSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ProfileSC = styled.div`
    align-items : center;
    display : flex;
    padding-right : 10px;
    justify-content : center;
    box-sizing : border-box;
    margin-right : 15px;
    z-index : 100;

    &>p {
        color : white;
        font-size : ${headerFontSize};
        margin-left : 5px;
    }

    .login-menu {
        position : absolute; 
        top : 40px;
        border : 1px solid lightgrey;
        right : 0;
        display : flex;
        flex-direction : column;
        background-color : white;
        border-radius : 5px;
        min-width : 200px;
    }

    .menu-item {
        display : flex;
        justify-content : space-between;
        align-items : center;
        padding : 20px;

        &:hover {
            cursor : pointer;
        }
    }
`

export default Profile