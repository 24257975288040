import React from 'react';
import styled from 'styled-components';
import TrainingOverview from '../TrainingOverview/TrainingOverview';
import { IndividualTrainingContext } from '../IndividiualTraining';
import Actions from '../Actions/Actions';
import UpcomingTrainings from '../UpcomingTrainings/UpcomingTrainings';
import CustomProperties from '../../IndividualSession/Dashboard/CustomProperties/CustomProperties';
import MailSchedule from '../MailSchedule/MailSchedule';
import AddInstance from '../../addInstance2/AddInstance';

const Overview = ({ state, setState  }) => {

    const { individualTraining, addInstanceToggled, setAddInstanceToggled } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <OverviewSC>

                {addInstanceToggled && <AddInstance 
                    state={state} 
                    setState={setState} 
                    individualTraining={individualTraining}    
                    setAddInstanceToggled={setAddInstanceToggled}
                />}


                <div style={{ display : 'flex'}}>
                    <TrainingOverview individualTraining={individualTraining}/>
                    <Actions />
                    <UpcomingTrainings />
                </div>
                <div style={{ display : 'flex'}}>
                    <CustomProperties individualTraining={individualTraining}/>
                    <MailSchedule individualTraining={individualTraining}/>
                </div>
            </OverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const OverviewSC = styled.div`
    display : flex;
    flex-direction : column;
    overflow-y : scroll;

`

export default Overview