import React from 'react';
import styled from 'styled-components';
import {  EditorContent } from '@tiptap/react'
import TitleIcon from '@mui/icons-material/Title';
import AbcIcon from '@mui/icons-material/Abc';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const TipTapEditor = ({ editor }) => {

    const rendering = () => {
        return (
            <TipTapEditorSC>
                <div className="editor-toolbar">
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            editor.chain().focus().toggleHeading({ level: 1 }).run()}
                        }
                        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                    >
                        <TitleIcon />
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            editor.chain().focus().setParagraph().run()}
                        }
                        className={editor.isActive('paragraph') ? 'is-active' : ''}
                    >
                        <AbcIcon />
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            editor.chain().focus().toggleBulletList().run()}
                        }
                        className={editor.isActive('bulletList') ? 'is-active' : ''}
                    >
                        <FormatListBulletedIcon />
                    </button>

                </div>
                <EditorContent 
                    editor={editor} 
                    className='editor-content'
                    spellCheck={false}    
                />
            </TipTapEditorSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TipTapEditorSC = styled.div`
    min-height : 400px;
    display : flex; 
    flex-direction : column;
    flex-grow : 1;

    .editor-toolbar {
        display : flex;
        
        button {
            background-color : #fafafa;
            border : 1px solid #ddd;
            padding : 8px;
            cursor : pointer;
            display : flex;
            justify-content : center;
            align-items : center;
            color : grey;

            &.is-active {
                background-color : #f0f0f0;
            }
        }
    }

    .editor-content {
        border: 1px solid #ddd;
        padding: 25px;
        border-radius: 8px;
        min-height: 200px;
        flex-grow : 1;
        overflow-y : scroll;

        .tiptap {
            outline : none;
            flex-grow : 1;

            h1 {
                color : #D20A4B;
                margin : 0;
                font-family : 'Outfit', sans-serif;
                font-weight : 400;
                font-size : 1.2rem;
            }

            p {
                margin : 0;
                color : #1C4050;
            }
        }

        &>h1 {
            color : red;
        }

    }
`

export default TipTapEditor